import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import React from "react";
import { linkResolver } from "src/linkResolver";
import { Box } from "@mui/material";
import AppButton from "../components/global/elements/AppButton";

const styles = {
  root: {
    minHeight: "calc(100vh - 80px - 90px)",
    textAlign: "center",
    paddingTop: "10rem",
  },
};

const NotFoundPage = () => {
  return (
    <Box sx={styles.root}>
      <h1>Not Found</h1>
      <p>The content you&apos;re looking for has moved or do not exist.</p>
      <br />
      <Box>
        <AppButton
          look="green"
          url="/"
          internalLink
          label="Take me away from here"
        />
      </Box>
    </Box>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
]);
